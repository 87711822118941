<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <h2 class="text-2xl font-weight-semibold text--primary">
              {{ appTitle }}
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-1xl font-weight-semibold text--primary text-center mb-2">
            Welcome to our Questionnaire! 👋🏻
          </p>
          <p class="mb-2 text-center">
            Please sign-in to your account
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              :rules="[v => !!v || 'Email is required', v => /.+@.+/.test(v) || 'Email must be valid']"
              placeholder="john@example.com"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              :rules="[v => !!v || 'Password is required', v => (v && v.length >= 8) || 'Min 8 characters']"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox label="Remember Me" hide-details class="me-3 mt-1"></v-checkbox>

              <!-- forgot link -->
              <a href="javascript:void(0)" class="mt-1">
                Forgot Password?
              </a>
            </div>

            <v-btn block color="primary" class="mt-6" @click="formSubmit">
              Login
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <!-- <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            New on our platform?
          </span>
          <router-link :to="{ name: 'pages-register' }">
            Create an account
          </router-link>
        </v-card-text> -->
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";
import Auth from "@/services/AuthService";
import { auth, onAuthStateChanged } from "@/utils/firebase";

export default {
  data: () => ({
    appTitle: process.env.VUE_APP_TITLE,
    isPasswordVisible: false,
    valid: true,
    loading: false,
    email: "",
    password: "",
    icons: {
      mdiEyeOutline,
      mdiEyeOffOutline,
    },
  }),

  methods: {
    async formSubmit() {
      if (this.$refs.form.validate()) {
        try {
          await Auth.loginWithEmailAndPassword(this.email, this.password).then(() => {
            onAuthStateChanged(auth, user => {
              if (user) {
                this.$router.replace({ name: "dashboard" });
              }
            });
          });
        } catch (error) {
          this.$store.dispatch("notification/add", { type: "error", message: "Data not available" });
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";
</style>
